<template>
  <el-dialog
    :modal="false"
    :title="title"
    :visible.sync="showDialog"
    width="800px"
    top="15vh"
    :close-on-click-modal="false"
    destroy-on-close
  >
    <div v-if="type != 4" class="status-tip">
      <img v-show="form.status == 0" src="@/assets/dsh.png" alt="" />
      <img v-show="form.status == 1" src="@/assets/ysh.png" alt="" />
      <img v-show="form.status == 2" src="@/assets/yzf.png" alt="" />
    </div>
    <div v-loading="loading" class="orderTest-container">
      <el-form
        ref="form"
        :model="form"
        inline
        label-position="right"
        label-width="80px"
        :disabled="type == 1 ? true : false"
      >
        <el-form-item
          v-if="type == 2 || type == 4"
          label="申请人:"
          prop="user_id"
          style="width: 45%"
        >
          <el-select
            v-model="form.user_id"
            clearable
            style="width: 193px"
            placeholder="申请人"
          >
            <el-option
              v-for="(i, idx) in peopleSelect"
              :key="idx"
              :value="i.user_id"
              :label="i.user_name"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          v-if="type == 1 || type == 3"
          label="单号:"
          prop="bill_code"
          style="width: 45%"
        >
          <el-input
            v-model="form.bill_code"
            placeholder="单号"
            :disabled="type == 3"
          ></el-input>
        </el-form-item>
        <el-form-item
          v-if="type == '1' || type == '3'"
          label="类型:"
          prop="type_text"
          style="width: 45%"
        >
          <el-input
            v-model="form.type_text"
            placeholder="类型"
            :disabled="type == 3"
          ></el-input>
        </el-form-item>
        <el-form-item
          v-if="type == '2' || type == '4'"
          label="类型:"
          prop="type"
          style="width: 45%"
        >
          <el-radio-group v-model="form.type" @change="readioChang">
            <el-radio :label="1">收入</el-radio>
            <el-radio :label="2">支出</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item
          v-if="type == 1 || type == 3"
          label="申请人:"
          prop="user_name"
          style="width: 45%"
        >
          <el-input
            v-model="form.user_name"
            placeholder="申请人"
            :disabled="type == 3"
          ></el-input>
        </el-form-item>

        <el-form-item
          v-if="type == '1' || type == '3'"
          label="申请时间:"
          prop="create_at"
          style="width: 45%"
        >
          <el-input v-model="form.create_at" :disabled="type == 3"></el-input>
        </el-form-item>
        <el-form-item
          v-if="form.status != 0"
          label="审核人:"
          prop="check_name"
          style="width: 45%"
        >
          <el-input v-model="form.check_name"></el-input>
        </el-form-item>
        <el-form-item
          v-if="form.status != 0"
          label="审核时间:"
          prop="check_at"
          style="width: 45%"
        >
          <el-input v-model="form.check_at"></el-input>
        </el-form-item>
        <el-form-item label="金额:" prop="money" style="width: 45%">
          <el-input v-model="form.money" type="number" :min="1"></el-input>
        </el-form-item>
        <el-form-item
          v-if="type == 1"
          label="费用科目:"
          prop="funds_type_text"
          style="width: 45%"
        >
          <el-input v-model="form.funds_type_text"></el-input>
        </el-form-item>
        <el-form-item
          v-if="type != 1"
          label="费用科目:"
          prop="funds_type"
          style="width: 45%"
        >
          <el-select
            v-model="form.funds_type"
            clearable
            style="width: 140px"
            placeholder="费用科目"
          >
            <el-option
              v-for="(i, idx) in financialSelect"
              :key="idx"
              :value="i.id"
              :label="i.name"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="备注:" prop="remark" style="width: 45%">
          <el-input v-model="form.remark"></el-input>
        </el-form-item>
        <el-form-item label="票据:" prop="" style="width: 90%">
          <div class="dx">
            <div
              v-for="(item, index) in form.imgs"
              :key="index"
              style="position: relative; margin-right: 25px"
            >
              <el-image
                style="width: 160px; height: 160px"
                :src="item.img"
                :z-index="22222"
                :preview-src-list="srcList"
              >
                <div slot="error" class="image-slot2">
                  <i class="el-icon-picture-outline"></i>
                </div>
              </el-image>
              <i
                v-if="type != 1"
                class="el-icon-error close"
                @click="picClose(index)"
              ></i>
              <div style="font-size: 16px; text-align: center">
                <el-input
                  v-model="item.desc"
                  :disabled="type == 1"
                  style="width: 160px"
                ></el-input>
                <div v-if="type != 1" style="margin-top: 10px">
                  <!-- <el-button v-if="type != 1" type="primary">上传</el-button> -->
                  <el-upload
                    :action="baseURL + '/baseAdmin/common/upload-image'"
                    list-type="picture"
                    name="files"
                    accept=".png, .jpg, .jpeg, .gif, .bmp, .jfif"
                    :show-file-list="false"
                    :headers="headers"
                    :before-upload="
                      (...event) => {
                        loadBefore(event, index)
                      }
                    "
                    :on-success="
                      (...event) => {
                        onloadSucc(event, index)
                      }
                    "
                    :on-error="onloadError"
                  >
                    <el-button size="small" type="primary">点击上传</el-button>
                    <!-- <div slot="tip" class="el-upload__tip">
                      只能上传jpg/png文件，且不超过500kb
                    </div> -->
                  </el-upload>
                </div>
              </div>
            </div>
          </div>
        </el-form-item>
        <el-form-item v-if="type == 3" label="审批金额:" prop="pre_money">
          <el-input
            v-model="form.pre_money"
            style="width: 100px; margin-right: 10px"
            type="number"
            :min="1"
          ></el-input>
          <el-select
            v-model="form.account_id"
            clearable
            style="width: 140px"
            placeholder="账户"
          >
            <el-option
              v-for="(i, idx) in accountSelect"
              :key="idx"
              :value="i.id"
              :label="i.name"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          v-if="type == 1"
          label="资金审批:"
          prop=""
          style="width: 90%"
        >
          <el-table stripe :data="form.check_log">
            <el-table-column
              prop="type_text"
              label="类型"
              width=""
              align="center"
            ></el-table-column>
            <el-table-column
              prop="amount"
              label="金额"
              width=""
              align="center"
            ></el-table-column>
            <el-table-column
              prop="account_name"
              label="账户"
              width="110"
              align="center"
            ></el-table-column>
            <el-table-column
              prop="create_at"
              label="时间"
              width="160"
              align="center"
            ></el-table-column>
          </el-table>
        </el-form-item>
      </el-form>
    </div>
    <div
      slot="footer"
      class="dialog-footer"
      style="margin-top: 5px; text-align: right"
    >
      <el-button
        v-if="type == 2 || type == 4"
        type="primary"
        @click="handleAdd"
      >
        提交
      </el-button>
      <el-button v-if="type == 3" type="primary" @click="handleReview">
        审核
      </el-button>
      <el-button v-if="type == 1 || type == 3">打印</el-button>
      <el-button @click="close">取 消</el-button>
    </div>
  </el-dialog>
</template>
<script>
  import {
    constDetail,
    accountList,
    accountAdd,
    accountReview,
  } from '@/api/financial'
  import { baseURL } from '@/config'
  export default {
    name: 'Edit',
    components: {},
    props: {
      peopleSelect: {
        type: Array,
        default: () => [],
      },
      subjectSelect: {
        type: Array,
        default: () => [],
      },
    },
    data() {
      return {
        title: '标题',
        loading: false,
        showDialog: false,
        headers: { token: '' },
        accountSelect: [],
        zhichuSelect: [],
        shouruSelect: [],
        // 1 查看 2修改 3 审核 4 添加
        type: '1',
        id: '',
        srcList: [], //图片大图
        form: {
          user_name: '',
          user_mobile: '',
          user_id: '',
          type: '',
          type_text: '', //类型
          status: '', //状态，0未审核，1已审核，2已作废
          money: '',
          pre_money: '',
          check_name: '',
          check_at: '',
          funds_type: '',
          funds_type_text: '',
          check_log: [],
          remark: '',
          account_id: '', //账户id
          bill_code: '', // '单号',
          supp_name: '', // '供应商',
          supp_contact: '', // '联系人',
          supp_phone: '', // '联系人电话',
          order_ori_code: '', // '采购单号',
          pay_user_name: '', // '付款人',
          pay_amount: '', // '付款金额',
          payment_account: '', // ' 付款账户',
          create_at: '', // ' 付款时间',
          imgs: [],
          arr_type: [],
        },
      }
    },
    computed: {
      baseURL() {
        return baseURL
      },
      financialSelect() {
        this.subjectSelect.forEach((item) => {
          console.log(item)
          if (item.name == '收入') {
            this.shouruSelect = item.list
          } else if (item.name == '支出') {
            this.zhichuSelect = item.list
          }
        })
        if (this.form.type == 1) {
          return this.shouruSelect
        } else if (this.form.type == 2) {
          return this.zhichuSelect
        }
        return []
      },
    },
    watch: {
      // 'form.type'(val, o) {
      //   if (o == '' || (val && (this.type == 4 || this.type == 2))) {
      //     this.form.funds_type = ''
      //   }
      // },
      subjectSelect(val) {
        if (val) {
          // val.forEach((item) => {
          //   console.log(item)
          //   if (item.name == '收入') {
          //     this.shouruSelect = item.list
          //   } else if (item.name == '支出') {
          //     this.zhichuSelect = item.list
          //   }
          // })
        }
      },
      showDialog(val) {
        if (val) {
          console.log(this.id)
          if (this.type != 4) {
            // 不是添加
            this.fetchData()
          } else {
            this.picAdd()
          }
        } else {
          this.resetForm()
        }
      },
    },
    created() {
      this.headers.token = this.$store.getters['user/accessToken']
      // 账户下拉
      accountList().then((res) => {
        this.accountSelect = res.data
      })
    },
    mounted() {},
    methods: {
      async fetchData() {
        this.loading = true
        let { data, msg } = await constDetail({ id: this.id })
        console.log(data)
        this.form = data
        this.form.pre_money = this.form.money
        this.picAdd()
        this.refreshBigPic()
        this.loading = false
      },
      picClose(index) {
        console.log(index)
        this.form.imgs[index].img = ''
      },
      resetForm() {
        this.id = ''
        this.form.create_at = ''
        this.form.funds_type = ''
        this.form.funds_type_text = ''
        this.form.check_log = []
        this.form.check_at = ''
        this.form.check_name = ''
        this.form.funds_type = ''
        this.form.imgs = []
        this.form.pre_money = ''
        this.form.user_mobile = ''
        this.form.user_id = ''
        this.form.status = ''
        this.form.type = ''
        this.form.money = ''

        this.$refs.form.resetFields()
      },
      close() {
        this.showDialog = false
      },
      readioChang(val) {
        console.log(val)
        if (val) {
          this.form.funds_type = ''
        }
      },
      picAdd() {
        let imgs = this.form.imgs
        let l = imgs.length
        console.log(imgs, l)
        if (l > 2) {
          console.log('>2')
          for (let i = 0; i < l; i++) {
            if (i > 1) {
              console.log(i)
              imgs.splice(2, 1)
            }
          }
        } else {
          console.log('小于2')

          // for (let i = 0; i < 2; i++) {
          //   if (imgs.length < 2) {
          //     imgs.push({
          //       img: '',
          //       desc: '',
          //     })
          //   }
          // }
        }
        console.log(imgs)
      },
      refreshBigPic() {
        let bigPic = []
        this.form.imgs.forEach((item) => {
          bigPic.push(item.img)
        })
        this.srcList = bigPic
      },
      handleReview() {
        let data = JSON.parse(JSON.stringify(this.form))
        let imgs = JSON.stringify(data.imgs)
        accountReview(
          Object.assign(data, { id: this.id, imgs, amount: data.pre_money })
        ).then((res) => {
          this.$message.success(res.msg)
          this.$emit('refresh')
          this.close()
        })
      },
      // 添加||修改
      handleAdd() {
        let data = JSON.parse(JSON.stringify(this.form))
        let imgs = JSON.stringify(data.imgs)
        accountAdd(Object.assign(data, { id: this.id, imgs })).then((res) => {
          this.$message.success(res.msg)
          this.$emit('refresh')
          this.close()
        })
      },
      // 上传前
      loadBefore(event, index) {
        let file = event[0]
        console.log('上传前 的钩子')
        console.log(file, index)
        if (file.size > 5242880) {
          this.$message.error('本地上传图片大小不能超过5MB')
          return false
        }
        if (
          file.type == 'image/png' ||
          file.type == 'image/jpeg' ||
          file.type == 'image/gif' ||
          file.type == 'image/bmp'
        ) {
        } else {
          this.$message.error('上传图片支持gif、jpg、png、jpeg、bmp、jfif')
          return false
        }
      },
      // 上传成功
      onloadSucc(event, index) {
        let res = event[0]
        let file = event[1]
        let fileList = event[2]
        console.log('上传成功时 的钩子')
        console.log(res, index)
        if (res.code == 200) {
          let url = res.data[0].url
          this.form.imgs[index].img = url
          this.refreshBigPic()
        }
      },
      // 上传失败
      onloadError(err, file, fileList) {
        console.log('上传失败 的钩子')
        console.log(err)
        this.$message.error('上传图片失败，请重试')
        return false
      },
    },
  }
</script>
<style lang="scss" scoped>
  .dx {
    display: flex;
  }
  .close {
    position: absolute;
    top: 0px;
    right: 0px;
    font-size: 24px;
    cursor: pointer;
    background: #fff;
    border-radius: 50%;
  }
</style>
<style lang="css"></style>
