var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        modal: false,
        title: _vm.title,
        visible: _vm.showDialog,
        width: "800px",
        top: "15vh",
        "close-on-click-modal": false,
        "destroy-on-close": "",
      },
      on: {
        "update:visible": function ($event) {
          _vm.showDialog = $event
        },
      },
    },
    [
      _vm.type != 4
        ? _c("div", { staticClass: "status-tip" }, [
            _c("img", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.form.status == 0,
                  expression: "form.status == 0",
                },
              ],
              attrs: { src: require("@/assets/dsh.png"), alt: "" },
            }),
            _c("img", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.form.status == 1,
                  expression: "form.status == 1",
                },
              ],
              attrs: { src: require("@/assets/ysh.png"), alt: "" },
            }),
            _c("img", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.form.status == 2,
                  expression: "form.status == 2",
                },
              ],
              attrs: { src: require("@/assets/yzf.png"), alt: "" },
            }),
          ])
        : _vm._e(),
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          staticClass: "orderTest-container",
        },
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                model: _vm.form,
                inline: "",
                "label-position": "right",
                "label-width": "80px",
                disabled: _vm.type == 1 ? true : false,
              },
            },
            [
              _vm.type == 2 || _vm.type == 4
                ? _c(
                    "el-form-item",
                    {
                      staticStyle: { width: "45%" },
                      attrs: { label: "申请人:", prop: "user_id" },
                    },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "193px" },
                          attrs: { clearable: "", placeholder: "申请人" },
                          model: {
                            value: _vm.form.user_id,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "user_id", $$v)
                            },
                            expression: "form.user_id",
                          },
                        },
                        _vm._l(_vm.peopleSelect, function (i, idx) {
                          return _c("el-option", {
                            key: idx,
                            attrs: { value: i.user_id, label: i.user_name },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.type == 1 || _vm.type == 3
                ? _c(
                    "el-form-item",
                    {
                      staticStyle: { width: "45%" },
                      attrs: { label: "单号:", prop: "bill_code" },
                    },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "单号", disabled: _vm.type == 3 },
                        model: {
                          value: _vm.form.bill_code,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "bill_code", $$v)
                          },
                          expression: "form.bill_code",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.type == "1" || _vm.type == "3"
                ? _c(
                    "el-form-item",
                    {
                      staticStyle: { width: "45%" },
                      attrs: { label: "类型:", prop: "type_text" },
                    },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "类型", disabled: _vm.type == 3 },
                        model: {
                          value: _vm.form.type_text,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "type_text", $$v)
                          },
                          expression: "form.type_text",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.type == "2" || _vm.type == "4"
                ? _c(
                    "el-form-item",
                    {
                      staticStyle: { width: "45%" },
                      attrs: { label: "类型:", prop: "type" },
                    },
                    [
                      _c(
                        "el-radio-group",
                        {
                          on: { change: _vm.readioChang },
                          model: {
                            value: _vm.form.type,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "type", $$v)
                            },
                            expression: "form.type",
                          },
                        },
                        [
                          _c("el-radio", { attrs: { label: 1 } }, [
                            _vm._v("收入"),
                          ]),
                          _c("el-radio", { attrs: { label: 2 } }, [
                            _vm._v("支出"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.type == 1 || _vm.type == 3
                ? _c(
                    "el-form-item",
                    {
                      staticStyle: { width: "45%" },
                      attrs: { label: "申请人:", prop: "user_name" },
                    },
                    [
                      _c("el-input", {
                        attrs: {
                          placeholder: "申请人",
                          disabled: _vm.type == 3,
                        },
                        model: {
                          value: _vm.form.user_name,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "user_name", $$v)
                          },
                          expression: "form.user_name",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.type == "1" || _vm.type == "3"
                ? _c(
                    "el-form-item",
                    {
                      staticStyle: { width: "45%" },
                      attrs: { label: "申请时间:", prop: "create_at" },
                    },
                    [
                      _c("el-input", {
                        attrs: { disabled: _vm.type == 3 },
                        model: {
                          value: _vm.form.create_at,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "create_at", $$v)
                          },
                          expression: "form.create_at",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.form.status != 0
                ? _c(
                    "el-form-item",
                    {
                      staticStyle: { width: "45%" },
                      attrs: { label: "审核人:", prop: "check_name" },
                    },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.form.check_name,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "check_name", $$v)
                          },
                          expression: "form.check_name",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.form.status != 0
                ? _c(
                    "el-form-item",
                    {
                      staticStyle: { width: "45%" },
                      attrs: { label: "审核时间:", prop: "check_at" },
                    },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.form.check_at,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "check_at", $$v)
                          },
                          expression: "form.check_at",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "el-form-item",
                {
                  staticStyle: { width: "45%" },
                  attrs: { label: "金额:", prop: "money" },
                },
                [
                  _c("el-input", {
                    attrs: { type: "number", min: 1 },
                    model: {
                      value: _vm.form.money,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "money", $$v)
                      },
                      expression: "form.money",
                    },
                  }),
                ],
                1
              ),
              _vm.type == 1
                ? _c(
                    "el-form-item",
                    {
                      staticStyle: { width: "45%" },
                      attrs: { label: "费用科目:", prop: "funds_type_text" },
                    },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.form.funds_type_text,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "funds_type_text", $$v)
                          },
                          expression: "form.funds_type_text",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.type != 1
                ? _c(
                    "el-form-item",
                    {
                      staticStyle: { width: "45%" },
                      attrs: { label: "费用科目:", prop: "funds_type" },
                    },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "140px" },
                          attrs: { clearable: "", placeholder: "费用科目" },
                          model: {
                            value: _vm.form.funds_type,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "funds_type", $$v)
                            },
                            expression: "form.funds_type",
                          },
                        },
                        _vm._l(_vm.financialSelect, function (i, idx) {
                          return _c("el-option", {
                            key: idx,
                            attrs: { value: i.id, label: i.name },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "el-form-item",
                {
                  staticStyle: { width: "45%" },
                  attrs: { label: "备注:", prop: "remark" },
                },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.form.remark,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "remark", $$v)
                      },
                      expression: "form.remark",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  staticStyle: { width: "90%" },
                  attrs: { label: "票据:", prop: "" },
                },
                [
                  _c(
                    "div",
                    { staticClass: "dx" },
                    _vm._l(_vm.form.imgs, function (item, index) {
                      return _c(
                        "div",
                        {
                          key: index,
                          staticStyle: {
                            position: "relative",
                            "margin-right": "25px",
                          },
                        },
                        [
                          _c(
                            "el-image",
                            {
                              staticStyle: { width: "160px", height: "160px" },
                              attrs: {
                                src: item.img,
                                "z-index": 22222,
                                "preview-src-list": _vm.srcList,
                              },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "image-slot2",
                                  attrs: { slot: "error" },
                                  slot: "error",
                                },
                                [
                                  _c("i", {
                                    staticClass: "el-icon-picture-outline",
                                  }),
                                ]
                              ),
                            ]
                          ),
                          _vm.type != 1
                            ? _c("i", {
                                staticClass: "el-icon-error close",
                                on: {
                                  click: function ($event) {
                                    return _vm.picClose(index)
                                  },
                                },
                              })
                            : _vm._e(),
                          _c(
                            "div",
                            {
                              staticStyle: {
                                "font-size": "16px",
                                "text-align": "center",
                              },
                            },
                            [
                              _c("el-input", {
                                staticStyle: { width: "160px" },
                                attrs: { disabled: _vm.type == 1 },
                                model: {
                                  value: item.desc,
                                  callback: function ($$v) {
                                    _vm.$set(item, "desc", $$v)
                                  },
                                  expression: "item.desc",
                                },
                              }),
                              _vm.type != 1
                                ? _c(
                                    "div",
                                    { staticStyle: { "margin-top": "10px" } },
                                    [
                                      _c(
                                        "el-upload",
                                        {
                                          attrs: {
                                            action:
                                              _vm.baseURL +
                                              "/baseAdmin/common/upload-image",
                                            "list-type": "picture",
                                            name: "files",
                                            accept:
                                              ".png, .jpg, .jpeg, .gif, .bmp, .jfif",
                                            "show-file-list": false,
                                            headers: _vm.headers,
                                            "before-upload": function () {
                                              var event = [],
                                                len = arguments.length
                                              while (len--)
                                                event[len] = arguments[len]

                                              _vm.loadBefore(event, index)
                                            },
                                            "on-success": function () {
                                              var event = [],
                                                len = arguments.length
                                              while (len--)
                                                event[len] = arguments[len]

                                              _vm.onloadSucc(event, index)
                                            },
                                            "on-error": _vm.onloadError,
                                          },
                                        },
                                        [
                                          _c(
                                            "el-button",
                                            {
                                              attrs: {
                                                size: "small",
                                                type: "primary",
                                              },
                                            },
                                            [_vm._v("点击上传")]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    }),
                    0
                  ),
                ]
              ),
              _vm.type == 3
                ? _c(
                    "el-form-item",
                    { attrs: { label: "审批金额:", prop: "pre_money" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "100px", "margin-right": "10px" },
                        attrs: { type: "number", min: 1 },
                        model: {
                          value: _vm.form.pre_money,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "pre_money", $$v)
                          },
                          expression: "form.pre_money",
                        },
                      }),
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "140px" },
                          attrs: { clearable: "", placeholder: "账户" },
                          model: {
                            value: _vm.form.account_id,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "account_id", $$v)
                            },
                            expression: "form.account_id",
                          },
                        },
                        _vm._l(_vm.accountSelect, function (i, idx) {
                          return _c("el-option", {
                            key: idx,
                            attrs: { value: i.id, label: i.name },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.type == 1
                ? _c(
                    "el-form-item",
                    {
                      staticStyle: { width: "90%" },
                      attrs: { label: "资金审批:", prop: "" },
                    },
                    [
                      _c(
                        "el-table",
                        { attrs: { stripe: "", data: _vm.form.check_log } },
                        [
                          _c("el-table-column", {
                            attrs: {
                              prop: "type_text",
                              label: "类型",
                              width: "",
                              align: "center",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "amount",
                              label: "金额",
                              width: "",
                              align: "center",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "account_name",
                              label: "账户",
                              width: "110",
                              align: "center",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "create_at",
                              label: "时间",
                              width: "160",
                              align: "center",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          staticStyle: { "margin-top": "5px", "text-align": "right" },
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _vm.type == 2 || _vm.type == 4
            ? _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.handleAdd } },
                [_vm._v(" 提交 ")]
              )
            : _vm._e(),
          _vm.type == 3
            ? _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.handleReview } },
                [_vm._v(" 审核 ")]
              )
            : _vm._e(),
          _vm.type == 1 || _vm.type == 3
            ? _c("el-button", [_vm._v("打印")])
            : _vm._e(),
          _c("el-button", { on: { click: _vm.close } }, [_vm._v("取 消")]),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }